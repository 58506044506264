import { useSidebarStoreSelectors } from "@/lib/zustand/sidebarStore";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

type NavigateOptions = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  state?: any;
  skipValidation?: boolean;
  highlight?: boolean;
  highlightItem?: string;
  skipDanger?: boolean;
};

export const useExpandedNavigate = (isDirty?: boolean) => {
  const { pathname } = useLocation();
  const routerDomNavigate = useNavigate();

  const setDangerModal = useSidebarStoreSelectors.use.setDangerModal();
  const setHref = useSidebarStoreSelectors.use.setHref();
  const showUnsavedDataWarning =
    useSidebarStoreSelectors.use.showUnsavedDataWarning();
  const setUnsavedDataWarning =
    useSidebarStoreSelectors.use.setUnsavedDataWarning();
  const setHrefToHighlight = useSidebarStoreSelectors.use.setHrefToHighlight();

  useEffect(() => {
    if (isDirty === undefined) return;

    setUnsavedDataWarning(isDirty);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty]);

  const expandedNavigate = (hrefTo: string, options?: NavigateOptions) => {
    setHref(hrefTo);

    if (options?.skipDanger) {
      setUnsavedDataWarning(false);
    }

    if (showUnsavedDataWarning && !options?.skipDanger) {
      setDangerModal({
        open: true,
        ...(options?.highlight && {
          itemToHighlight: options.highlightItem ?? hrefTo,
        }),
      });

      return;
    }

    if (options?.highlight) {
      setHrefToHighlight(options.highlightItem ?? hrefTo);
    }

    if (
      !options?.skipValidation &&
      (pathname === "/map" ||
        pathname === "/jobs/dashboard" ||
        pathname === "/jobs/job-detail")
    ) {
      window.location.assign(`/${hrefTo}`);
    } else {
      routerDomNavigate(`/${hrefTo}`, {
        ...(options?.state && { state: options.state }),
      });
    }
  };

  return expandedNavigate;
};
