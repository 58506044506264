import { AssetsExpandedContent, JobsExpandedContent } from "./ExpandedContent";
import { classNames } from "../../utils/helpers/classNames";
import { DangerModal } from "@/UI/Modal";
import { PATHNAME_REGEX } from "@/utils/helpers/pathnameRegex";
import { AdminExpandedContent } from "./ExpandedContent/AdminExpandedContent";
import { useAuthContext } from "../Auth/AuthWrapper";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { UserMenu } from "../UserMenu";
import { UsersExpandedContent } from "./ExpandedContent/UsersExpandedContent";
import { useSidebarStoreSelectors } from "@/lib/zustand/sidebarStore";
import {
  Nav,
  navBase,
  webSettingsNavigation,
  timecardAdminNavigation,
  webAssetCrudNavigation,
  webAssetMapNavigation,
  webQuotingNavigation,
  webScheduleNavigation,
  webUserCrudNavigation,
  webAdminNavigation,
} from "../../utils/navigation";
import { SettingsExpandedContent } from "./ExpandedContent/SettingsExpandedContent";
import { useExpandedNavigate } from "@/utils/hooks/useExpandedNavigate";

const Sidebar = () => {
  const {
    hasTimecardAdmin,
    hasRibbiotAdmin,
    hasAssetCrud,
    hasUserCrud,
    hasWebAssetMap,
    hasWebSchedule,
    hasWebQuoting,
    hasWebSettings,
  } = useAuthContext();

  const { pathname } = useLocation();
  const navigate = useNavigate();
  const expandedNavigate = useExpandedNavigate();

  const hrefTo = useSidebarStoreSelectors.use.href();
  const setUnsavedDataWarning =
    useSidebarStoreSelectors.use.setUnsavedDataWarning();
  const unsavedDataWarningMessage =
    useSidebarStoreSelectors.use.unsavedDataWarningMessage();
  const setDangerModal = useSidebarStoreSelectors.use.setDangerModal();
  const dangerModal = useSidebarStoreSelectors.use.dangerModal();
  const hrefToHighlight = useSidebarStoreSelectors.use.hrefToHighlight();
  const setHrefToHighlight = useSidebarStoreSelectors.use.setHrefToHighlight();

  const adminTopNav = [
    ...navBase,
    ...webAssetMapNavigation,
    ...webScheduleNavigation,
    ...timecardAdminNavigation,
    ...webQuotingNavigation,
    ...webAssetCrudNavigation,
    ...webUserCrudNavigation,
  ];
  const adminBottomNav = [...webAdminNavigation, ...webSettingsNavigation];

  const nav: Nav[] = hasRibbiotAdmin
    ? adminTopNav
    : [
        ...navBase,
        ...(hasWebSchedule ? [...webScheduleNavigation] : []),
        ...(hasWebAssetMap ? [...webAssetMapNavigation] : []),
        ...(hasTimecardAdmin ? [...timecardAdminNavigation] : []),
        ...(hasAssetCrud || hasWebAssetMap ? [...webAssetCrudNavigation] : []),
        ...(hasUserCrud ? [...webUserCrudNavigation] : []),
        ...(hasWebQuoting ? [...webQuotingNavigation] : []),
        ...(hasRibbiotAdmin ? [...webAdminNavigation] : []),
      ];

  const bottomNav = hasRibbiotAdmin
    ? adminBottomNav
    : [...(hasWebSettings ? [...webSettingsNavigation] : [])];

  const handleExpand = (pathname: string, nav: Nav[]) => {
    const sanitizedPathname = pathname.replace(PATHNAME_REGEX, "");
    const expandedRoutes = nav.flatMap((nav) => nav.expandedRoutes);
    const match = expandedRoutes.find((route) => sanitizedPathname === route);
    return Boolean(match);
  };

  const handleExpandedContent = (pathname: string, nav: Nav[]) => {
    const sanitizedPathname = pathname.replace(PATHNAME_REGEX, "");
    const currNav = nav.find((item) =>
      item.expandedRoutes.includes(sanitizedPathname)
    );

    if (currNav) {
      switch (currNav.name) {
        case "Schedule":
          return <JobsExpandedContent />;
        case "Map":
        case "Assets":
          return <AssetsExpandedContent />;
        case "Users":
          return <UsersExpandedContent />;
        case "Settings":
          return <SettingsExpandedContent />;
        case "Admin":
          return <AdminExpandedContent />;
        default:
          return <></>;
      }
    }
    return <></>;
  };

  useEffect(() => {
    const href = window.location.pathname.split("/")[1];

    switch (href) {
      case "jobs":
        setHrefToHighlight("schedule");
        break;
      case "assets":
        setHrefToHighlight("assets/browser");
        break;
      default:
        setHrefToHighlight(href);
        break;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <nav
        className={classNames(
          "z-10 flex h-full w-[60px] shrink-0 flex-col items-center justify-between bg-palette-quarternaryGrey pb-[34px] pt-6"
        )}
      >
        <ul className="flex w-full flex-col gap-y-8">
          {nav.map((item, idx) => {
            return (
              <li
                key={item.name}
                className={classNames(
                  "flex w-full flex-col items-center",
                  hrefToHighlight === item.href && idx > 0
                    ? "border-l-4 border-theme-green-primary"
                    : ""
                )}
              >
                <a
                  className={"group flex gap-x-3"}
                  href={`/${item.href}`}
                  onClick={(event) => {
                    event.preventDefault();
                    expandedNavigate(item.href, { highlight: true });
                  }}
                  title={item.name}
                >
                  <item.icon
                    aria-hidden="true"
                    stroke={classNames(
                      hrefToHighlight === item.href && idx > 0
                        ? "black"
                        : "#575757"
                    )}
                    fill={classNames(
                      hrefToHighlight === item.href && idx > 0
                        ? "black"
                        : "#575757"
                    )}
                  />
                </a>
              </li>
            );
          })}
        </ul>

        <ul className="flex w-full flex-col gap-y-[22px]">
          {bottomNav.map((item) => (
            <li
              key={item.name}
              className={classNames(
                "flex w-full flex-col items-center",
                pathname.includes(item.href) &&
                  "border-l-4 border-theme-green-primary"
              )}
            >
              <a
                className={"flex gap-x-3"}
                href={`/${item.href}`}
                onClick={(event) => {
                  event.preventDefault();
                  expandedNavigate(item.href, { highlight: true });
                }}
                title={item.name}
              >
                <item.icon />
              </a>
            </li>
          ))}

          <li className={classNames("flex w-full flex-col items-center")}>
            <UserMenu />
          </li>
        </ul>
      </nav>

      <div
        className={classNames(
          "flex h-full w-[164px] shrink-0 flex-col items-center bg-[#f2f7f9] pt-6",
          handleExpand(pathname, [...nav, ...bottomNav]) ? "flex" : "hidden"
        )}
      >
        {handleExpandedContent(pathname, [...nav, ...bottomNav])}
      </div>

      <DangerModal
        cancelAction={() => setDangerModal({ open: false })}
        confirmAction={() => {
          if (dangerModal.itemToHighlight) {
            setHrefToHighlight(dangerModal.itemToHighlight);
          }
          setUnsavedDataWarning(false);
          setDangerModal({ open: false });
          navigate(hrefTo);
        }}
        message={unsavedDataWarningMessage}
        open={dangerModal.open}
        title="Discard Changes?"
      />
    </>
  );
};

export { Sidebar };
