/* eslint-disable @typescript-eslint/no-explicit-any */
import { classNames } from "@/utils/helpers/classNames";
import { FC } from "react";
import {
  evaluateExpression,
  getTitleClassesByAttributes,
  transformTitleByFieldItem,
} from "@/utils/helpers/forms/formRendererHelpers";
import { RowField } from "@/types/forms/formEngine";

export interface RowRadioFieldProps {
  fieldItem: RowField;
  value: string;
}
export const RadioField: FC<RowRadioFieldProps> = ({ fieldItem, value }) => {
  const options = fieldItem?.attributes?.options?.split(",");
  const title = transformTitleByFieldItem(fieldItem);
  const hidden = evaluateExpression(fieldItem?.attributes?.hiddenIf);

  return (
    <div
      className={`space-y-1 ${hidden ? "hidden" : ""}`}
      style={{
        gridColumnStart: fieldItem?.columnStart,
        gridColumnEnd: fieldItem?.columnEnd,
      }}
    >
      {title && (
        <h4
          className={classNames(
            "py-2 text-sm font-medium text-theme-black-secondary",
            getTitleClassesByAttributes(fieldItem?.attributes)
          )}
        >
          {title}
        </h4>
      )}

      {value && (
        <div className="flex max-w-[500px] items-center gap-x-3">
          {options?.map((option, index) => {
            return (
              <div key={option + index} className="flex items-center">
                <input
                  className="h-4 w-4 border-gray-300 text-theme-green-primary focus:ring-0"
                  disabled
                  id={option}
                  name={title}
                  type="radio"
                  checked={
                    (value?.toLowerCase().trim() ===
                      option.toLowerCase().trim()) as any
                  }
                />
                <label
                  htmlFor={title}
                  className="ml-3 block text-sm font-medium leading-6 text-gray-900"
                >
                  {option}
                </label>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
