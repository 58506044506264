import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/20/solid";
import { classNames } from "@/utils/helpers/classNames";
import {
  FC,
  ReactNode,
  SelectHTMLAttributes,
  useEffect,
  useRef,
  useState,
} from "react";
import { FieldError } from "react-hook-form";
import { Option } from "@/types/option";

type SearchableSelectProps = Omit<
  SelectHTMLAttributes<HTMLSelectElement>,
  "value"
> & {
  clearAction?: () => void;
  containerClassName?: string;
  controlledSelect?: (option: Option) => void;
  controlledType?: (value: string) => void;
  error?: FieldError | undefined;
  inputClasses?: string;
  label?: string;
  options: Option[];
  readonly?: boolean;
  selectorContainerClasses?: string;
  showClear?: ReactNode | string;
  value?: string | Option;
};

const SearchableSelect: FC<SearchableSelectProps> = ({
  clearAction,
  containerClassName = "",
  controlledSelect,
  controlledType,
  disabled = false,
  error,
  inputClasses = "",
  label,
  options,
  placeholder = "Select",
  readonly = false,
  selectorContainerClasses = "",
  showClear,
  value = "",
}) => {
  const [dynamicOptions, setDynamicOptions] = useState(options);
  const [inputValue, setInputValue] = useState<string>();
  const [showSelector, setShowSelector] = useState(false);

  const containerRef = useRef<HTMLDivElement>(null);

  const onSelect = (option: Option) => {
    setInputValue(option.value);

    if (controlledSelect) {
      controlledSelect(option);
    }
  };

  const onType = (value: string) => {
    setInputValue(value);

    if (!value) {
      setDynamicOptions(options);
    } else {
      const relatedOptions = options.filter((option) =>
        option.value.toLowerCase().includes(value.toLowerCase())
      );
      setDynamicOptions(relatedOptions);
    }

    if (controlledType) {
      controlledType(value);
    }
  };

  const onClear = () => {
    onType("");

    if (clearAction) {
      clearAction();
    }
  };

  useEffect(() => {
    const clickListener = (click: MouseEvent) => {
      if (containerRef.current?.contains(click.target as HTMLElement)) {
        setShowSelector((prev) => !prev);
      } else {
        setShowSelector(false);
      }
    };

    const escListener = (keyPressed: KeyboardEvent) => {
      if (keyPressed.key === "Escape") {
        setShowSelector(false);
      }
    };

    window.addEventListener("click", clickListener);
    window.addEventListener("keydown", escListener);
    return () => {
      window.removeEventListener("click", clickListener);
      window.removeEventListener("keydown", escListener);
    };
  }, []);

  useEffect(() => {
    setDynamicOptions(options);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options.length]);

  return (
    <div
      ref={containerRef}
      className={classNames("group relative flex flex-col", containerClassName)}
    >
      {label && (
        <label
          className={classNames(
            "text-sm font-medium",
            disabled ? "text-[#97989e]" : "text-[#575757]"
          )}
        >
          {label}
        </label>
      )}

      <div className="relative">
        <input
          autoComplete="off"
          disabled={disabled}
          readOnly={readonly}
          id={label}
          aria-label={label}
          className={classNames(
            "h-10 w-full rounded-md border border-[#949494] bg-[#fafdfe] text-[#141c22] placeholder:leading-6 placeholder:text-[#97989e] hover:border-[#393d41]",
            disabled
              ? "border-[#ebebeb] bg-[#ecf2f2] text-[#97999f] hover:!border-[#ebebeb]"
              : "",
            error ? "border-[#D65D59]" : "",
            showClear ? "pr-[50px]" : "pr-[30px]",
            inputClasses
          )}
          onChange={({ target: { value } }) => onType(value)}
          placeholder={placeholder}
          type="text"
          value={
            typeof inputValue === "string"
              ? inputValue
              : typeof value === "string"
              ? value
              : value.value
          }
        />

        <div className="absolute right-1 top-2 flex items-center">
          {showClear && !disabled && value && (
            <button
              className="flex h-[18px] w-[18px] items-center justify-center rounded-full bg-[#b4b7b9]"
              type="button"
              onClick={onClear}
            >
              <span className="text-xs font-semibold text-white">x</span>
            </button>
          )}

          {!disabled && (
            <div className="flex items-center">
              {showSelector ? (
                <button type="button">
                  <ChevronUpIcon className="h-6 w-6" />
                </button>
              ) : (
                <button type="button">
                  <ChevronDownIcon className="h-6 w-6" />
                </button>
              )}
            </div>
          )}
        </div>
      </div>

      {showSelector && (
        <div
          className={classNames(
            `group absolute top-12 z-10 max-h-[300px] w-full 
            overflow-y-auto rounded-md border border-palette-tertiaryBlack 
          bg-palette-paleBlue`,
            selectorContainerClasses
          )}
        >
          <ul>
            {dynamicOptions.length ? (
              dynamicOptions.map((option) => (
                <li
                  key={option.id}
                  className="px-1 hover:bg-palette-tertiaryGrey"
                >
                  <button
                    type="button"
                    onClick={() => onSelect(option)}
                    className="flex h-10 w-full cursor-pointer items-center"
                  >
                    <p className="text-left">{option.value}</p>
                  </button>
                </li>
              ))
            ) : (
              <li className="flex h-10 w-full items-center px-1 hover:bg-palette-tertiaryGrey">
                <p>Not Listed</p>
              </li>
            )}
          </ul>
        </div>
      )}

      {error && (
        <p className="text-xs font-medium text-[#D65D59]">
          {error.message || "Error"}
        </p>
      )}
    </div>
  );
};

export { SearchableSelect };
