import "./styles/rp-styles.css";
import "./styles/ex-styles.css";

import React, {
  Dispatch,
  FC,
  ForwardedRef,
  forwardRef,
  SetStateAction,
  useRef,
  useState,
} from "react";
import { FieldError } from "react-hook-form";
import ReactDatePicker from "react-datepicker";
import { LeftArrow, RightArrow } from "../../assets";
import {
  endOfMonth,
  endOfWeek,
  format,
  getWeek,
  setWeek,
  startOfMonth,
  startOfWeek,
  subDays,
  subMonths,
} from "date-fns";
import { classNames } from "@/utils/helpers/classNames";

type ExtendedRangePickerProps = {
  className?: string;
  classNameExt?: string;
  dateFormat?: string | string[];
  disabled?: boolean;
  endDate: Date | null;
  error?: FieldError | undefined;
  label?: string;
  maxDate?: Date;
  minDate?: Date;
  onCalendarClose?: () => void;
  onChange: (_date: [Date | null, Date | null]) => void;
  onDateRange: (_values: [Date | null, Date | null]) => void;
  placeholderText?: string;
  showFilters?: boolean;
  startDate: Date | null;
};

type CustomInputProps = {
  dateFormat?: string | string[];
  endDate: Date | null;
  setOpen: Dispatch<SetStateAction<boolean>>;
  startDate: Date | null;
};

const CustomInput = forwardRef(function CustomInput(
  props: CustomInputProps,
  _ref: ForwardedRef<HTMLInputElement>
) {
  const containerRef = useRef<HTMLDivElement>(null);
  const { endDate, setOpen, startDate, dateFormat } = props;

  const parseValue = (date: Date | null, position: 0 | 1) => {
    if (!date) return "";
    if (!dateFormat || !Array.isArray(dateFormat)) {
      return format(date, "MMM dd, yyyy");
    }
    return format(date, dateFormat[position]);
  };

  return (
    <div
      className="flex w-[300px]"
      ref={containerRef}
      id="range-picker-inputs-container"
    >
      <input
        className="h-10 w-full rounded-md rounded-r-none border border-r-[0.5px] border-[#949494] bg-[#fafdfe] text-[#141c22] placeholder:leading-6 placeholder:text-[#97989e] hover:border-[#393d41]"
        onClick={() => setOpen(true)}
        readOnly
        type="text"
        value={parseValue(startDate, 0)}
      />

      <input
        className="h-10 w-full rounded-md rounded-l-none border border-l-[0.5px] border-[#949494] bg-[#fafdfe] text-[#141c22] placeholder:leading-6 placeholder:text-[#97989e] hover:border-[#393d41]"
        onClick={() => setOpen(true)}
        readOnly
        type="text"
        value={parseValue(endDate, 1)}
      />
    </div>
  );
});

const ExtendedRangePicker: FC<ExtendedRangePickerProps> = ({
  className = "",
  classNameExt = "rp",
  dateFormat = undefined,
  disabled = false,
  endDate,
  error,
  label,
  maxDate,
  minDate,
  onCalendarClose,
  onChange,
  onDateRange,
  placeholderText = "MM/DD/YY - MM/DD/YY",
  showFilters = true,
  startDate,
}) => {
  const [open, setOpen] = useState(false);

  const handleChange = (date: [Date | null, Date | null]) => {
    onChange(date);
  };

  const liRef = useRef<HTMLDListElement>();

  const chageButtonBg = (button: HTMLButtonElement) => {
    if (liRef.current) {
      liRef.current.classList.remove("bg-theme-green-primary");
    }

    liRef.current = button.parentNode as HTMLDListElement;
    liRef.current.classList.add("bg-theme-green-primary");
  };

  const onClickOutside = (ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const inputsContainer = document.getElementById(
      "range-picker-inputs-container"
    );

    if (inputsContainer?.contains(ev.target as HTMLElement)) return;

    if (startDate && !endDate) {
      onChange([startDate, startDate]);
    }

    setOpen(false);
  };

  console.log("Build test");

  return (
    <div className={classNames("flex flex-col", className)}>
      {label && (
        <label className="text-sm font-semibold text-theme-black-secondary">
          {label}
        </label>
      )}

      <ReactDatePicker
        allowSameDay
        calendarClassName={classNameExt}
        className={`${error && "border-theme-red-primary"}`}
        dateFormat={dateFormat}
        disabled={disabled}
        endDate={endDate}
        formatWeekDay={(day) => day.at(0)}
        maxDate={maxDate}
        minDate={minDate}
        monthsShown={2}
        onCalendarClose={onCalendarClose}
        onChange={handleChange}
        onClickOutside={(ev) => onClickOutside(ev)}
        open={open}
        openToDate={startDate as Date | undefined}
        placeholderText={placeholderText}
        popperClassName={classNameExt}
        selectsRange
        showPopperArrow={false}
        startDate={startDate}
        wrapperClassName={classNameExt}
        renderDayContents={(day) => (
          <div className="flex h-full w-full items-center justify-center">
            <span className="relative text-sm font-medium">{day}</span>
          </div>
        )}
        renderCustomHeader={({
          customHeaderCount,
          decreaseMonth,
          increaseMonth,
          monthDate,
          nextMonthButtonDisabled,
          prevMonthButtonDisabled,
        }) => {
          return (
            <div className="m-3 flex">
              <div className="flex w-full items-center justify-between ">
                {customHeaderCount === 0 ? (
                  <button
                    className=""
                    onClick={decreaseMonth}
                    disabled={prevMonthButtonDisabled}
                  >
                    <LeftArrow />
                  </button>
                ) : (
                  <div></div>
                )}

                <div
                  data-testid="datepicker-toggle-text"
                  className="flex gap-1"
                >
                  <span className="text-sm font-semibold">
                    {monthDate.toDateString().substring(4, 7)}
                  </span>
                  <span className="text-sm font-semibold">
                    {monthDate.toDateString().substring(11, 15)}
                  </span>
                </div>

                {customHeaderCount === 1 ? (
                  <button
                    onClick={increaseMonth}
                    disabled={nextMonthButtonDisabled}
                  >
                    <RightArrow />
                  </button>
                ) : (
                  <div></div>
                )}
              </div>
            </div>
          );
        }}
        customInput={
          <CustomInput
            dateFormat={dateFormat}
            endDate={endDate}
            setOpen={setOpen}
            startDate={startDate}
          />
        }
      >
        {showFilters ? (
          <div className="flex h-full flex-col items-center justify-center">
            <ul className="flex w-full flex-col gap-y-4 font-semibold">
              <li className="flex h-7 items-center justify-center">
                <button
                  onClick={(event) => {
                    onDateRange([new Date(), new Date()]);
                    chageButtonBg(event.target as HTMLButtonElement);
                  }}
                >
                  Today
                </button>
              </li>
              <li className="flex h-7 items-center justify-center">
                <button
                  onClick={(event) => {
                    onDateRange([
                      subDays(new Date(), 1),
                      subDays(new Date(), 1),
                    ]);
                    chageButtonBg(event.target as HTMLButtonElement);
                  }}
                >
                  Yesterday
                </button>
              </li>
              <li className="flex h-7 items-center justify-center">
                <button
                  onClick={(event) => {
                    onDateRange([
                      startOfWeek(new Date()),
                      endOfWeek(new Date()),
                    ]);
                    chageButtonBg(event.target as HTMLButtonElement);
                  }}
                >
                  This Week
                </button>
              </li>
              <li className="flex h-7 items-center justify-center">
                <button
                  onClick={(event) => {
                    const currentWeek = getWeek(new Date());
                    const lastWeek = setWeek(new Date(), currentWeek - 1);
                    const startOfLastWeek = startOfWeek(lastWeek);
                    const endOfLastWeek = endOfWeek(lastWeek);

                    onDateRange([startOfLastWeek, endOfLastWeek]);
                    chageButtonBg(event.target as HTMLButtonElement);
                  }}
                >
                  Last Week
                </button>
              </li>
              <li className="flex h-7 items-center justify-center">
                <button
                  onClick={(event) => {
                    const firsDayOfCurrentMonth = startOfMonth(new Date());
                    const lastDayOfCurrentMonth = endOfMonth(new Date());

                    onDateRange([firsDayOfCurrentMonth, lastDayOfCurrentMonth]);
                    chageButtonBg(event.target as HTMLButtonElement);
                  }}
                >
                  This Month
                </button>
              </li>
              <li className="flex h-7 items-center justify-center">
                <button
                  onClick={(event) => {
                    const lastMonth = subMonths(new Date(), 1);
                    const firstDayOfLastMonth = startOfMonth(lastMonth);
                    const lastDayOfLastMonth = endOfMonth(lastMonth);

                    onDateRange([firstDayOfLastMonth, lastDayOfLastMonth]);
                    chageButtonBg(event.target as HTMLButtonElement);
                  }}
                >
                  Last Month
                </button>
              </li>
            </ul>
          </div>
        ) : null}
      </ReactDatePicker>

      {error && (
        <p className="text-[12px] font-medium text-theme-red-primary">
          {error.message || "Error"}
        </p>
      )}
    </div>
  );
};

export { ExtendedRangePicker };
